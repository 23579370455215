:root {
  --dark-primary: #181818;
  --dark-light: #212121;
  --blue: #0099ff;
  --green: #00912b;
  --red: #e80000;
  --white: #fff;
  --gray: #8d91a0;
  --dark-gray: #3d3d3d;
  --light-gray: #aaaaaa;
  --shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  --curve: 8px;
}
/*-------------------------- [ DEFAULT STYLE ] ----------------------------*/
.uncopy {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bg-danger {
  background-color: var(--red) !important;
}
.btn-danger {
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}
.btn-success {
  background-color: var(--green) !important;
  border-color: var(--green) !important;
}
.curve {
  border-radius: var(--curve);
}

.btn-dark {
  background-color: var(--dark-primary) !important;
  border-color: var(--dark-primary) !important;
  transition: 0.3s;
}
.btn-dark:hover {
  transition: 0.3s;
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}
.bg-dark {
  background-color: var(--dark-primary) !important;
  border-color: var(--dark-primary) !important;
}
.shadow {
  box-shadow: var(--shadow) !important;
}
a {
  text-decoration: none;
  cursor: pointer;
}
input {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
}
input:focus {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.8);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: none;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--dark-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark-gray);
}
.form-control:disabled,
.form-control[readonly] {
  background-color: var(--dark-primary);
}
/* app */
body {
  background-color: var(--dark-primary);
}
.app-badge {
  padding: 5px 10px;
  border-radius: none;
}
.bg-danger {
  background-color: var(--red) !important;
}
.bg-success {
  background-color: var(--green);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-body {
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 83px;
  padding-left: 15px;
  padding-right: 10px;
  display: flex;
}
.left-fixed-panel {
  position: fixed;
  top: 73px;
  left: 0;
  width: 20%;
  height: 100vh;
  background-color: var(--dark-light);
  border-right: 1px solid var(--dark-gray);
  transition: 0.3s;
}
.right-content-panel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
}
.right-content-panel-inner {
  width: 80%;
}
.panel-resize-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--dark-light);
  position: absolute;
  top: 10px;
  right: -15px;
  z-index: 2021;
  border: 1px solid var(--dark-gray);
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}
.panel-resize-btn:hover {
  background-color: var(--dark-gray);
  transition: 0.3s;
}
.top-bar {
  position: fixed;
  width: 100%;
  height: 73px;
  left: 0px;
  top: 0px;
  z-index: 2021;
  background-color: var(--dark-light);
  padding: 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid var(--dark-gray);
}
.circle-icon {
  position: relative;
  background-color: var(--dark-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
  transition: 0.2s;
  box-shadow: var(--shadow);
}
.circle-icon .icon-badge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}
.circle-icon:hover {
  transition: 0.2s;
  background-color: var(--red);
}
.circle-icon ion-icon {
  font-size: 20px;
}

.search {
  border-radius: 6px;
  background-color: var(--dark-primary);
  margin: 0 20px;
  box-shadow: var(--shadow);
}

.search .input-search {
  width: 100%;
}
.search .input-search .input-search-box {
  width: 100%;
  height: 100%;
  color: var(--white);
  background-color: var(--dark-primary);
  border: none;
  padding: 0 15px;
}
.search .input-search-btn-wrapper {
  width: 50px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search .input-search-btn-wrapper ion-icon {
  font-size: 20px;
  color: #fff;
}

.side-bar {
  position: fixed;
  width: 240px;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--dark-light);
  color: #fff;
  z-index: 2020;
  display: flex;
  flex-direction: column;
  padding-top: 73px;
  border-right: 1px solid var(--dark-gray);
}
.side-bar .profile {
  width: 100%;
  height: 117px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.side-bar .profile .avatar {
  width: 55px;
  height: 55px;
  border: 3px solid var(--white);
  background-color: var(--dark-primary);
  border-radius: 50%;
  margin-right: 10px;
}
.side-bar .profile .avatar .avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.side-bar .profile .user-details {
  display: flex;
  flex-direction: column;
  color: var(--white);
  font-weight: 400;
  font-size: 15px;
}

.user-status {
  font-size: 14px;
  font-weight: 300;
  color: var(--green);
}

.side-nav {
  width: 100%;
  height: 100%;
  margin-top: 15px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}
.side-nav .side-nav-item {
  width: 100%;
  height: 50px;
  margin: 5px 0;
  padding: 0 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: var(--white);
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}
.side-nav .side-nav-item:hover {
  transition: 0.3s;
  background-color: var(--dark-gray);
}
.side-nav .side-nav-item span {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0 15px;
  margin-left: 30px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.side-nav .side-nav-item ion-icon {
  font-size: 25px;
}

.side-nav .side-nav-item.active-nav {
  background-color: var(--dark-gray);
}

/*----------------- data table --------------------*/
/*
.data-table-wrapper {
  background-color: var(--dark-light);
  border-radius: 6px;
}

.data-table-head tr {
  border-bottom: 2px solid var(--dark-gray);
  height: 60px;
}
.data-table-head tr th {
  height: 60px;
}
.data-table-head tr th div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: var(--gray);
  text-transform: capitalize;
}

.data-table-body tr {
  border-bottom: 1px solid var(--dark-gray);
  transition: 0.3s;
  height: 60px;
}

.data-table-body tr td {
  padding: 0;
}
.data-table-body tr td div {
  color: var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
}

.data-table-wrapper .table-footer {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-table-wrapper .table-footer .adjust-raw-data {
  color: var(--white);
}

.table-action-btn {
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.table-action-btn .icon {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
  border: 1px solid var(--dark-gray);
  box-shadow: var(--shadow);
}
.table-action-btn .icon:hover {
  transition: 0.3s;
  background-color: var(--dark-gray);
}

.table-action-btn .icon ion-icon {
  font-size: 20px;
}
*/
/*----------------------- CUSTOM POP UP MODEL ------------------------*/
.custom-popup {
  background-color: var(--dark-primary);
  height: auto;
  margin-top: 73px;
  box-shadow: var(--shadow);
  border: 1px solid var(--dark-light);
}

/*--------------- [ MEDIA QUERY ] ------------*/
@media screen and (min-width: 800px) {
  .modal-dialog {
    max-width: none;
    width: 700px;
    margin-left: 380px;
  }
}

.custom-popup .custom-popup-header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--dark-gray);
}
.custom-popup .custom-popup-header .head-title {
  color: var(--white);
  text-transform: capitalize;
}
.custom-popup .custom-popup-header .model-close-btn {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
  border: 1px solid var(--dark-gray);
  box-shadow: var(--shadow);
  color: var(--white);
  font-size: 25px;
  cursor: pointer;
}

.custom-popup .custom-popup-header .model-close-btn:hover {
  transition: 0.3s;
  background-color: var(--dark-gray);
}

/*invoice data popup content*/
.payment-details-popup {
  display: flex;
  flex-direction: column;
}

.payment-details-popup .card-header {
  padding: 5px;
  color: #fff;
  text-transform: capitalize;
}
.payment-details-body {
  display: flex;
  flex-direction: column;
}
.payment-details-body .payment-details-body-item {
  padding: 10px;
  color: var(--white);
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--dark-gray);
}
.payment-details-body .payment-details-body-item .badge {
  padding: 3px 7px;
  margin-left: 5px;
  letter-spacing: 2px;
  font-size: 14px;
}

.payment-details-body .payment-details-body-item .btn {
  margin-left: 5px;
  padding: 5px 10px;
  color: var(--white);
}

/*--------------------------- invoice-info-wrapper ---------------------*/
.invoice-info-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.invoice-info-wrapper .invoice-info-head {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--dark-light);
}
.invoice-info-wrapper .invoice-info-head .invoice-cutomer-info {
  display: flex;
  flex-direction: column;
}

.invoice-info-wrapper
  .invoice-info-head
  .invoice-cutomer-info
  .invoice-cutomer-info-item {
  color: var(--white);
  font-size: 12px;
  text-transform: capitalize;
  padding: 2px 0;
  letter-spacing: 1px;
}

.invoice-info-date-id {
  display: flex;
  flex-direction: column;
}
.invoice-info-date-id .invoice-info-date-id-item {
  color: var(--white);
  font-size: 12px;
  text-transform: capitalize;
  padding: 2px 0;
  letter-spacing: 1px;
}

.invoice-info-wrapper .invoice-info-body {
  padding: 15px 0;
  border-bottom: 1px solid var(--dark-light);
}
.invoice-item-amount {
  color: var(--white);
  font-size: 12px;
  text-transform: capitalize;
  padding: 2px 0;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
}
.invoice-item-amount span {
  padding-left: 20px;
}

.invoice-info-wrapper .invoice-info-footer {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.invoice-info-wrapper .invoice-info-body .invoice-auther-info {
  color: var(--white);
  font-size: 12px;
  padding: 2px 0;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
}
.invoice-info-wrapper .invoice-info-body .invoice-auther-info span {
  padding-left: 5px;
}
.paid-image {
  height: 100%;
  width: 100px;
  margin-right: 15px;
}

/*------------ CREATE INVOICE PAGE -------------*/

.add-bill-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 20px;
  box-shadow: var(--shadow);
  border-radius: 3px;
  background-color: var(--dark-light);
}

.add-bill-wrapper .bill-header {
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
}

.add-bill-wrapper .invoice-details-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.add-bill-wrapper .invoice-details-wrapper .left-details {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.add-bill-wrapper .invoice-details-wrapper .left-details .bill-to-header {
  text-transform: capitalize;
  font-weight: 500;
  padding-left: 0;
}

.bill-to-head {
  margin: 15px 0;
}

.invoice-to-message {
  text-transform: uppercase;
  font-weight: 500;
  color: var(--red);
  font-size: 20px;
}

.add-bill-wrapper .invoice-details-wrapper .right-details {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.bill-info .bill-info-head {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: end;
}

.bill-info .invoice-no-wrapper {
  width: 100%;
  display: flex;
  justify-content: end;
}
.bill-info .invoice-no-wrapper .invoice-no {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: var(--white);
}
.invoice-no-badge {
  padding: 8px 25px;
  border-radius: 0;
  font-size: 20px;
  letter-spacing: 2px;
  background-color: var(--green) !important;
}

.bill-to input,
.bill-info input {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
  border-radius: 6px;
}

.bill-to input:focus {
  background-color: var(--dark-primary);
  color: var(--white);
}

.bill-info input:focus {
  background-color: var(--dark-primary);
  color: var(--white);
}

.invoice-make-area-wrapper {
  width: 100%;
  margin-top: 15px;
}
.invoice-make-head {
  height: 50px;
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--dark-gray);
}
.invoice-make-head-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-weight: 500;
  letter-spacing: 1px;
}

.invoice-item-input {
  height: 50px;
  width: auto;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
}
.invoice-item-input-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
}
.invoice-item-input-box input {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
  border-radius: 0;
}

.invoice-item-input-box input:focus {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
}
.auther-info {
  background-color: transparent;
}
.auther-info label {
  color: var(--white);
  font-weight: 500;
}
.auther-info input {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
  width: 30%;
}
.auther-info input:focus {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
}

.invoice-action-btn {
  margin-right: 10px;
  padding: 8px 40px;
}

/*----------------------------- Invoice Preview Style ---------------------------*/

.invoice {
  width: auto;
  height: 100%;
}
.invoice-header {
  height: 100px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.invoice-header img {
  height: 80px;
  width: auto;
}
.invoice-header span {
  font-weight: bold;
  font-size: 10px;
}

.invoice .invoice-content {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.invoice .invoice-content .left-card {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -5px;
}
.invoice .invoice-content .left-card .company-address {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.invoice .invoice-content .left-card .company-address span {
  font-weight: 500;
  color: #000;
  font-size: 13px;
  text-transform: capitalize;
}

.invoice .invoice-content .left-card .company-to {
  margin-top: 30px;
}
.invoice .invoice-content .left-card .company-to .to-item-box {
  background-color: #fff;
  width: 50%;
  margin-left: 20px;
  font-size: 13px;
  font-weight: 500;
  padding-left: 0;
  text-transform: capitalize;
}

.invoice .invoice-content .right-card {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.invoice .invoice-content .right-card .invoice-number {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: flex-end;
  margin-top: 5px;
}
.invoice .invoice-content .right-card .invoice-number .invoice-number-box {
  padding: 6px 20px;
  border-radius: 5px;
  margin-top: 2px;
}

.invoice .invoice-content .right-card .invoice-badge {
  display: flex;
  justify-content: end;
}
.invoice .invoice-content .right-card .invoice-badge .invoice-badge-box {
  background-color: var(--white);
  padding: 0;
  margin-top: 0;
  color: #000;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: bold;
  margin-top: -25px;
}

.invoice-table {
  width: 100%;
  margin-top: 30px;
}
.invoice-table thead tr th {
  text-align: center;
}
.invoice-table tbody tr td {
  text-align: center;
  font-size: 13px;
}
.end-declaration {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.invoice .invoice-content .company-recever {
  width: 50%;
  margin-top: 50px;
}

.invoice .invoice-content .company-recever .to-item-box {
  height: 30px;
  background-color: #fff;
  width: 50%;
  margin-left: 65px;
}
.invoice .invoice-content .company-accountant {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 25px;
}
.invoice .invoice-content .company-accountant .company-account-signature {
  padding: 5px 20px;
  border-top: 1px solid #000;
  font-size: 13px;
  font-weight: 500;
}

.invoice-footer {
  width: 100%;
  height: 40px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invoice-footer span {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }
  html,
  body {
    width: 210mm;
    height: 282mm;
    background: #fff;
    overflow: visible;
  }
  body {
    padding-top: 2mm;
    padding-bottom: 0mm;
  }
}

.payment-info {
  background-color: transparent;
  margin-bottom: 15px;
}
.payment-info span {
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  text-transform: capitalize;
  margin-right: 10px;
}

/*-------------------------- [ APP LOGIN ] --------------------------*/

.app-login {
  background-color: var(--dark-primary);
}
.app-login .card {
  background-color: var(--dark-light);
  box-shadow: var(--shadow);
}
.app-login .card .card-header {
  font-weight: bold;
  color: var(--white);
}
.app-login .card .card-body label {
  color: var(--white);
}

.app-login .card .card-body input {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
}
.app-login .card .card-body input:focus {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
}

/*---------------------------------- [ APP ALERT] --------------------------------*/
.app-alert {
  padding: 5px 7px;
  background-color: var(--dark-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border: 1px solid var(--dark-gray);
  border-radius: 6px;
  transition: 0.3s;
  cursor: pointer;
}
.app-alert:hover {
  background-color: var(--dark-light);
  transition: 0.3s;
}
.app-alert span {
  font-size: 13px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: bold;
}
.app-alert button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 25px;
  width: 25px;
  border: none;
}

.app-alert button ion-icon {
  font-size: 13px;
}

.app-alert.ok span {
  color: var(--green);
}

.app-alert.error span {
  color: var(--red);
}

.app-alert.ok button {
  background-color: var(--green);
}
.app-alert.error button {
  background-color: var(--red);
}

/*------------------------- [ CONTENT AREA STYLE ] ----------------------------*/
.content-container {
  padding-left: 255px;
  padding-top: 88px;
  padding-right: 15px;
  padding-bottom: 15px;
  background-color: var(--dark-primary);
  height: auto;
  width: 100%;
}

/*------------------------- [ DATA TABLE STYLE ] ----------------------------*/
.data-table {
  height: auto;
  width: auto;
  background-color: var(--dark-light);
  border-radius: var(--curve);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.data-table-head {
  height: 65px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.data-table-head .rhead,
.data-table-head .lhead {
  width: 50%;
  height: 100%;
  padding: 10px 15px;
  color: var(--white);
}
.data-table-head .rhead {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.data-table-head .rhead .rMainTitle {
  font-weight: 500;
  font-size: 17px;
  color: var(--white);
}
.data-table-head .rhead .rSubTitle {
  font-weight: 500;
  font-size: 12px;
  color: var(--light-gray);
}

.data-table-head .lhead {
  display: flex;
  justify-content: flex-end;
}
.data-table-footer {
  height: auto;
  width: 100%;
}
.data-table-body {
  height: auto;
  width: 100%;
  padding: 10px 15px;
}

.data-table-body tr th {
  border-bottom: 2px solid var(--dark-gray) !important;
  /*border: 1px solid var(--divider);*/
}
.data-table-body tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--dark-gray) !important;
}
.data-table-body .th {
  height: 100%;
  width: 100%;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 15px;
  color: var(--white);
}
.data-table-body tbody {
  border: none !important;
}

.data-table-body td {
  border-bottom: none;
}
.data-table-body .td {
  height: 30px;
  width: 100%;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-weight: normal;
  font-size: 13px;
  color: var(--light-gray);
}

.table-action-btn {
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.table-action-btn .icon {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
  border: 1px solid var(--dark-gray);
  box-shadow: var(--shadow);
}
.table-action-btn .icon:hover {
  transition: 0.3s;
  background-color: var(--dark-gray);
}

.table-action-btn .icon ion-icon {
  font-size: 20px;
}

/*------------------------- [ CUSTOM PAGINATION STYLE ] ----------------------------*/

.custom-pagination > .pagination > li > a {
  background-color: var(--dark-primary) !important;
  border: 1px solid var(--dark-gray) !important;
  box-shadow: var(--shadow);
  transition: 0.3s;
  color: var(--red);
}
.custom-pagination > .pagination > li > a:hover {
  transition: 0.3s;
  background-color: var(--dark-gray) !important;
}

.custom-pagination > .pagination li.active {
  background-color: var(--red) !important;
  color: var(--white);
}

.custom-pagination > .pagination .page-item.active .page-link {
  background-color: var(--red) !important;
  color: var(--white);
}

/*-------------------------------- [ CUSTOM INPUT ]------------------------------------*/
.custom-input,
.custom-input:focus {
  background-color: var(--dark-primary);
  border: 1px solid var(--dark-gray);
  color: var(--white);
}

/*-------------------------------- [ CUSTOM DIVIDER ]------------------------------------*/

.custom-divider {
  height: 1px;
  background-color: var(--dark-gray);
  margin: 20px 0;
}

/*-------------------------------- [ CUSTOM RESULT SHOW COMPONENT ]------------------------------------*/
.result-list {
  width: 100%;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: 1px solid var(--dark-gray);
  box-shadow: var(--shadow);
  padding: 10px;
}
.result-list > .head {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.result-list > .head > .title {
  font-weight: bold;
  font-size: 15px;
  color: var(--white);
  display: flex;
  align-items: center;
}
.result-list > .head > .closebtn {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.result-list > .head > .closebtn button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-gray);
  box-shadow: var(--shadow);
}
.result-list > .head > .closebtn button:hover {
  background-color: var(--red);
}

.result-list > .result-item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: var(--dark-primary);

  margin-bottom: 10px;
  transition: 0.3s;
}
.result-list > .result-item > .item-name {
  font-weight: bold;
  font-size: 15px;
  color: var(--white);
  display: flex;
  align-items: center;
}
.result-list > .result-item > .item-action {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
}

.result-list > .result-item > .item-action > span {
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-------------------------------- [ LIST PRODUCT SUGGEST ]------------------------------------*/
.list-product-suggest {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: var(--dark-primary);
  z-index: 2021;
  width: 100%;
}

/*----------------------------------------- FILRER UI --------------------------------------------*/
.filter-ui-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-ui {
  height: auto;
  width: auto;
  overflow-y: scroll;
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
}
.filter-ui:first-child {
  align-items: end;
}
.filter-item {
  height: 100%;
  width: auto;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.filter-item input,
.filter-item select {
  background-color: var(--dark-primary);
  border: none;
  color: var(--white);
  border: 1px solid var(--divider);
  width: 150px;
  height: 30px;
  font-size: 12px;
}

.filter-item button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  height: 30px;
  padding: 0 10px;
  margin-right: 10px;
}
.filter-item button ion-icon {
  color: var(--white);
  font-weight: bold;
  font-size: 18px;
}
.filter-item button span {
  color: var(--white);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.filter-item span {
  font-weight: normal;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: var(--light-gray);
}

/*------------------------- [ APP BUTTON STYLE ] ----------------------------*/
.app-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px;
  color: var(--white);
}
.app-button .app-btn-text {
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: bold;
}
.app-button ion-icon {
  margin-right: 5px;
  font-size: 25px;
}
.app-button ion-icon:only-child {
  margin: 0;
}
.app-button .app-spinner {
  color: var(--white);
}
.app-button:disabled {
  opacity: unset;
}
